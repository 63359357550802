import React from 'react';

export const SiteContext = React.createContext({
  preview: false,
  sitemap: [],
  config: {},
  page: {},
  scope: process.env.NEXT_PUBLIC_SCOPE, // <'corporate' | 'consumer'>
  pageNavigation: { cta: null }, // override global navigation: { cta: []}
});
