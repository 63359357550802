import React, { useState, useEffect, useRef } from 'react';

import { useInView } from '../../hooks/useInView';

import styles from './LazyLoadInView.module.css';

export const LazyLoadInView = ({
  children,
  enabled = true,
  background,
  className,
  networkIdle,
}) => {
  const wrapperRef = useRef(null);
  const doLoad = useInView({
    elementRef: wrapperRef,
    threshold: 0.01,
    once: true,
    rootMargin: '1200px',
  });

  const [forceLoad, setForceLoad] = useState(!enabled);

  useEffect(() => {
    let idleCallback;
    if (forceLoad === true) return;
    if (!networkIdle) return;

    function load() {
      setForceLoad(true);
    }

    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      idleCallback = requestIdleCallback(load);
    }

    () => cancelIdleCallback(idleCallback);
  }, [forceLoad, networkIdle]);

  return (
    <div ref={wrapperRef} className={className}>
      {doLoad || forceLoad ? (
        children
      ) : (
        <div style={{ background }} className={styles.placeholder} />
      )}
    </div>
  );
};
