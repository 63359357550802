import { useEffect, useState } from 'react';

export const useInView = ({
  elementRef,
  threshold = 0,
  once = false,
  root,
  rootMargin = '0px',
}) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!elementRef?.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting }) => {
          setInView(isIntersecting);
          if (isIntersecting && once) {
            observer.disconnect();
          }
        });
      },
      { rootMargin, threshold },
    );

    observer.observe(elementRef.current);

    return () => {
      setInView(false);
      observer.disconnect();
    };
  }, [elementRef, threshold, root, rootMargin, once]);

  return inView;
};

export const useInViewRatio = ({ elementRef, assumeRatio = 0 }) => {
  const [ratio, setRatio] = useState(assumeRatio);
  const inView = useInView({ elementRef });

  useEffect(() => {
    if (!inView) return;
    function onScroll() {
      if (!elementRef?.current) return;
      const rect = elementRef.current.getBoundingClientRect();
      const ratio =
        (1 / rect.height) * (rect.height + rect.top - elementRef.current.offsetTop);
      setRatio(ratio);
    }

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [inView]);

  return ratio;
};
