import dynamic from 'next/dynamic';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import { Suspense } from 'react';

import { LazyLoadInView } from '../LazyLoad/LazyLoadInView';
import SectionErrorBoundary from './SectionErrorBoundary';

const SECTIONS = {
  ChartDashboard: dynamic(
    () => import(/* webpackChunkName: "ChartDashboard" */ './ChartDashboard'),
    {
      suspense: true,
    },
  ),
  AboutPeople: dynamic(
    () => import(/* webpackChunkName: "AboutPeople" */ './AboutPeople'),
    {
      suspense: true,
    },
  ),
  AnalystCards: dynamic(
    () => import(/* webpackChunkName: "AnalystCards" */ './AnalystCards'),
    {
      suspense: true,
    },
  ),
  ArticleHeader: dynamic(
    () => import(/* webpackChunkName: "ArticleHeader" */ './ArticleHeader'),
    {
      suspense: true,
    },
  ),
  BreadsCrumbs: dynamic(
    () => import(/* webpackChunkName: "BreadsCrumbs" */ './BreadsCrumbs'),
    {
      suspense: true,
    },
  ),
  CardBlocks: dynamic(
    () => import(/* webpackChunkName: "CardBlocks" */ './CardBlocks'),
    { suspense: true },
  ),
  ContactSupportBlocks: dynamic(
    () =>
      import(
        /* webpackChunkName: "ContactSupportBlocks" */ './ContactSupportBlocks'
      ),
    {
      suspense: true,
    },
  ),
  Corporate: dynamic(
    () => import(/* webpackChunkName: "Corporate" */ './Corporate'),
    { suspense: true },
  ),
  Developments: dynamic(
    () => import(/* webpackChunkName: "Developments" */ './Developments'),
    {
      suspense: true,
    },
  ),
  Divider: dynamic(() => import(/* webpackChunkName: "Divider" */ './Divider'), {
    suspense: true,
  }),
  FeaturedDevelopments: dynamic(
    () =>
      import(
        /* webpackChunkName: "FeaturedDevelopments" */ './FeaturedDevelopments'
      ),
    {
      suspense: true,
    },
  ),
  FilterableArticles: dynamic(
    () =>
      import(/* webpackChunkName: "FilterableArticles" */ './FilterableArticles'),
    {
      suspense: true,
    },
  ),
  FinanceCalculator: dynamic(
    () => import(/* webpackChunkName: "FinanceCalculator" */ './FinanceCalculator'),
    {
      suspense: true,
    },
  ),
  FinancialInvestorCalendar: dynamic(
    () =>
      import(
        /* webpackChunkName: "FinancialInvestorCalendar" */ './FinancialInvestorCalendar'
      ),
    {
      suspense: true,
    },
  ),
  FinancialResults: dynamic(
    () => import(/* webpackChunkName: "FinancialResults" */ './FinancialResults'),
    {
      suspense: true,
    },
  ),
  FloorPlans: dynamic(
    () => import(/* webpackChunkName: "FloorPlans" */ './FloorPlans'),
    { suspense: true },
  ),
  Gallery: dynamic(() => import(/* webpackChunkName: "Gallery" */ './Gallery'), {
    suspense: true,
  }),
  GallerySimple: dynamic(
    () => import(/* webpackChunkName: "GallerySimple" */ './GallerySimple'),
    {
      suspense: true,
    },
  ),
  GraphsiFrame: dynamic(
    () => import(/* webpackChunkName: "GraphsiFrame" */ './GraphsiFrame'),
    {
      suspense: true,
    },
  ),
  HelpBlock: dynamic(
    () => import(/* webpackChunkName: "HelpBlock" */ './HelpBlock'),
    { suspense: true },
  ),
  Hero: dynamic(() => import(/* webpackChunkName: "Hero" */ './Hero'), {
    suspense: true,
  }),
  HouseFeatures: dynamic(
    () => import(/* webpackChunkName: "HouseFeatures" */ './HouseFeatures'),
    {
      suspense: true,
    },
  ),
  ImageGridLink: dynamic(
    () => import(/* webpackChunkName: "ImageGridLink" */ './ImageGridLink'),
    {
      suspense: true,
    },
  ),
  InfoStats: dynamic(
    () => import(/* webpackChunkName: "InfoStats" */ './InfoStats'),
    { suspense: true },
  ),
  KeyDevelopmentDetails: dynamic(
    () =>
      import(
        /* webpackChunkName: "KeyDevelopmentDetails" */ './KeyDevelopmentDetails'
      ),
    {
      suspense: true,
    },
  ),
  KeyPoints: dynamic(
    () => import(/* webpackChunkName: "KeyPoints" */ './KeyPoints'),
    { suspense: true },
  ),
  LatestNews: dynamic(
    () => import(/* webpackChunkName: "LatestNews" */ './LatestNews'),
    { suspense: true },
  ),
  Maps: dynamic(() => import(/* webpackChunkName: "Maps" */ './Maps'), {
    suspense: true,
  }),
  NavigationSignposts: dynamic(
    () =>
      import(/* webpackChunkName: "NavigationSignposts" */ './NavigationSignposts'),
    {
      suspense: true,
    },
  ),
  NewsList: dynamic(() => import(/* webpackChunkName: "NewsList" */ './NewsList'), {
    suspense: true,
  }),
  OverviewText: dynamic(
    () => import(/* webpackChunkName: "OverviewText" */ './OverviewText'),
    {
      suspense: true,
    },
  ),
  PrimaryPropertyCards: dynamic(
    () =>
      import(
        /* webpackChunkName: "PrimaryPropertyCards" */ './PrimaryPropertyCards'
      ),
    {
      suspense: true,
    },
  ),
  ProccesDiagram: dynamic(
    () => import(/* webpackChunkName: "ProccesDiagram" */ './ProccesDiagram'),
    {
      suspense: true,
    },
  ),
  PropertyPanel: dynamic(
    () => import(/* webpackChunkName: "PropertyPanel" */ './PropertyPanel'),
    {
      suspense: true,
    },
  ),
  RichQuoteBanner: dynamic(
    () => import(/* webpackChunkName: "RichQuoteBanner" */ './RichQuoteBanner'),
    {
      suspense: true,
    },
  ),
  SharePrice: dynamic(
    () => import(/* webpackChunkName: "SharePrice" */ './SharePrice'),
    { suspense: true },
  ),
  SignPostCard: dynamic(
    () => import(/* webpackChunkName: "SignPostCard" */ './SignPostCard'),
    {
      suspense: true,
    },
  ),
  SingleDocumentTable: dynamic(
    () =>
      import(/* webpackChunkName: "SingleDocumentTable" */ './SingleDocumentTable'),
    {
      suspense: true,
    },
  ),
  SiteMap: dynamic(() => import(/* webpackChunkName: "SiteMap" */ './SiteMap'), {
    suspense: true,
  }),
  TextAndImage: dynamic(
    () => import(/* webpackChunkName: "TextAndImage" */ './TextAndImage'),
    {
      suspense: true,
    },
  ),
  TextAndImageCorporate: dynamic(
    () =>
      import(
        /* webpackChunkName: "TextAndImageCorporate" */ './TextAndImageCorporate'
      ),
    {
      suspense: true,
    },
  ),
  TextModule: dynamic(
    () => import(/* webpackChunkName: "TextModule" */ './TextModule'),
    { suspense: true },
  ),
  Video: dynamic(() => import(/* webpackChunkName: "Video" */ './Video'), {
    suspense: true,
  }),
  CardGrid: dynamic(
    () => import(/* webpackChunkName: "CardGrid" */ './CardGrid/CardGrid'),
    { suspense: true },
  ),
  FloatingButton: dynamic(
    () =>
      import(
        /* webpackChunkName: "FloatingButton" */ './FloatingButton/FloatingButton'
      ),
    { suspense: true },
  ),
};

// sections that need to be loaded before network idle or inview
const NON_LAZY_LOAD_SECTIONS = [
  'Hero',
  'BreadsCrumbs',
  'OverviewText',
  'FloatingButton',
];

// sections that can be loaded only when in view, not network idle
const INVIEW_LOAD_ONLY_SECTIONS = ['Maps', 'Video'];

const RenderSections = (props) => {
  const { sections, document, pageType, page, slug, id, type } = props;
  if (!sections?.length) return null;
  const initialSectionsToLoad = 2;

  return (
    <div>
      {sections.map((section, i) => {
        const sectionName =
          section._type.charAt(0).toUpperCase() + section._type.slice(1);
        const SectionComponent = SECTIONS[sectionName];
        if (!SectionComponent) {
          console.warn(`Missing module ${sectionName}`);
          return null;
        }

        return (
          <Suspense fallback={``} key={section._key}>
            <SectionErrorBoundary>
              <LazyLoadInView
                // show essential sections immediately
                enabled={
                  i > initialSectionsToLoad &&
                  NON_LAZY_LOAD_SECTIONS.indexOf(sectionName) === -1
                }
                // load non essential sections after network idle
                // and heavy non essential sections only when in view
                networkIdle={INVIEW_LOAD_ONLY_SECTIONS.indexOf(sectionName) === -1}
                background={
                  (
                    section.bgColor ||
                    section.background ||
                    section.backgroundColor ||
                    section.bgColorModule ||
                    section.theme ||
                    section.backgroundTheme ||
                    section.colorTitle
                  )?.value
                }
                className={`section-${section._type}`}
              >
                <SectionComponent
                  {...section}
                  document={document}
                  pageType={pageType}
                  page={page}
                  slug={slug.current || slug}
                  id={id}
                  type={type}
                />
              </LazyLoadInView>
            </SectionErrorBoundary>
          </Suspense>
        );
      })}
    </div>
  );
};

RenderSections.propTypes = {
  sections: arrayOf(shape({})),
  document: shape({}),
  pageType: string,
  page: string,
  slug: shape({}),
  id: string,
  type: string,
};

// noinspection JSUnusedLocalSymbols
RenderSections.defaultProps = {
  sections: [],
  document: {},
  pageType: '',
  page: '',
  slug: {},
  id: '',
  type: '',
};

export default RenderSections;
