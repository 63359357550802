import dynamic from 'next/dynamic';
import { bool, arrayOf, node, shape, string } from 'prop-types';
import React from 'react';
import { Suspense } from 'react';

import { SiteContext } from '../../context';
import { LazyLoadInView } from '../LazyLoad/LazyLoadInView';

import styles from './Layout.module.scss';

const Spinner = dynamic(
  () =>
    import(
      /* webpackChunkName: "Spinner" */ '../../components/RegisterInterest/components/Spinner/Spinner'
    ),
  {
    suspense: true,
  },
);
const PreviewButtons = dynamic(
  () =>
    import(
      /* webpackChunkName: "PreviewButtons" */ 'components/preview/PreviewButtons'
    ),
  {
    suspense: true,
  },
);
const Footer = dynamic(
  () => import(/* webpackChunkName: "Footer" */ '../nav/Footer'),
  {
    suspense: true,
  },
);

const Nav = dynamic(() => import(/* webpackChunkName: "Nav" */ '../nav/Nav'), {
  suspense: true,
});

const Layout = (props) => {
  const {
    config,
    loading,
    children,
    sitemap,
    preview,
    reloadPreview,
    previewLoading,
    pagePath,
    sitemapItem,
    pageNavigation,
  } = props;

  const scope = process.env.NEXT_PUBLIC_SCOPE;

  if (!config) {
    console.error('Missing site config');
    return <div>Missing site config</div>;
  }

  return (
    <SiteContext.Provider
      value={{ config, sitemap, preview, page: sitemapItem, scope, pageNavigation }}
    >
      <Suspense fallback={``}>
        {preview && (
          <PreviewButtons
            reloadPreview={reloadPreview}
            previewLoading={previewLoading}
            pagePath={pagePath}
          />
        )}

        <div className={styles.container}>
          <div className={styles.content}>
            {sitemapItem && !preview && <Nav />}

            {loading ? (
              <div className={styles.loader}>
                <Spinner />
              </div>
            ) : (
              children
            )}

            {!preview && (
              <LazyLoadInView networkIdle>
                <Footer />
              </LazyLoadInView>
            )}
          </div>
        </div>
      </Suspense>
    </SiteContext.Provider>
  );
};

Layout.propTypes = {
  children: arrayOf(node),
  loader: bool,
  config: shape({
    title: string,
    socialLinks: shape({}),
    navigationItems: arrayOf(shape({})),
    footerMeta: arrayOf(shape({})),
    contacts: shape({}),
  }),
};

Layout.defaultProps = {
  children: [],
  config: {
    title: '',
    socialLinks: {},
    navigationItems: [],
    footerMeta: {},
    contacts: {},
  },
};

export default Layout;
